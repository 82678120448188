import React, { FC } from "react"

import { Helmet } from "react-helmet"

interface ISeo {
  title: string
  description: string
  image?: string
  keywords?: string
  children?: JSX.Element
}

export const Seo: FC<ISeo> = ({ title, description, keywords, image = "/zeta-requisite.jpg", children }) => (
  <Helmet>
    <title>{title}</title>
    <meta charSet="utf-8" />
    <meta name="robots" content="all" />
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    <meta property="og:locale" content="ru" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    <meta property="og:image:width" content="569" />
    <meta property="og:image:height" content="469" />
    <meta property="og:site_name" content="ЗЭТА - Завод электротехники и автоматики" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />
  </Helmet>
)
