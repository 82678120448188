const characteristics = [
  { param: "Количество вводов ", value: "1 - 4 шт." },
  {
    param: "Количество секций",
    value: "1 - 4 шт.",
  },
  {
    param: "Количество отходящих линий",
    value: "до 20-60 шт",
  },
  { param: "* по спецзаказу возможно до 100" },
]

const peculiarities = [
  "Максимальная токовая защита от КЗ и перегрузки",
  "Автоматический контроль изоляции",
  "Автоматический поиск фидеров с пониженным сопротивлением изоляции между полюсами «плюс» и «минус» и землей",
]

const composition = [
  "В качестве коммутационной аппаратуры используются:\n" +
    "автоматические выключатели постоянного тока двухполюсные с дополнительными контактами (как отечественные (АП-50), так и выключатели зарубежного производства фирм Schneider Electric, ABB, Moeller, OEZ и др.)\n" +
    "модульные рубильники-разъединители с предохранителями",
  "Устройства автоматического контроля изоляции и поиска поврежденного фидера производства ЭКРА-СКИ (НПП «ЭКРА», фирмы BENDER (Германия) и др. (исполнение ШНЭ 8002, тип определяет Заказчик исходя)",
  "Мнемосхема на двери шкафа",
  "Устройства ограничения импульсных перенапряжений на секции (опция)",
  "Местная световая индикация состояния коммутационной аппаратуры",
  "Подключение экранированных кабелей;",
  "Элементы системы мониторинга (сигнализация положения и срабатывания коммутационных аппаратов)",
  "Независимые расцепители для автоматических выключателей.",
  "Исходя из токов короткого замыкания на шинах ШРОТ, в шкафы монтируются диоды, которые обеспечивают малое время восстановления напряжения на шинах ШРОТ при коротких замыканиях в цепях ШРОТ.",
  "Проведены испытания шкафа распределения оперативного тока ШНЭ8002А с «ЭКРА-СКИ» на стойкость к механическим внешним воздействующим факторам, включая сейсмостойкость, регламентированным ГОСТ 17516.1-90, группа механического исполнения М7, а также на требования по ЭМС при степени жесткости испытаний 4.",
]

export { characteristics, composition, peculiarities }
