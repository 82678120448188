import sliderImage1 from "../../../../public/index-page-slide_1-bg.jpg"
import sliderImage2 from "../../../../public/index-page-slide_2-bg.jpg"
import index_page_slide_1_icon_1 from "../../../../public/index-page-slide_1-icon_1.png"
import index_page_slide_1_icon_2 from "../../../../public/index-page-slide_1-icon_2.png"
import index_page_slide_1_icon_3 from "../../../../public/index-page-slide_1-icon_3.png"
import index_page_slide_2_icon from "../../../../public/index-page-slide_2-icon.svg"
import index_page_slide_3icon1 from "../../../../public/index-page-slide_3-icon-1.png"
import index_page_slide_3icon2 from "../../../../public/index-page-slide_3-icon-2.png"
import index_page_slide_3icon3 from "../../../../public/index-page-slide_3-icon-3.png"

type SlideItems = {
  imgUrl: string
  title: string
  subtitle: string
  features: { icon: string; text: string }[]
}

export const slideItems: SlideItems[] = [
  {
    imgUrl: sliderImage1,
    title: "Профили деятельности компании",
    subtitle:
      "Реализация комплексных электротехнических проектов, а также широкий спектр услуг по изготовлению пластиковых изделий и деталей.",
    features: [
      {
        icon: index_page_slide_1_icon_1,
        text: "Наличие собственного производства",
      },
      {
        icon: index_page_slide_1_icon_2,
        text: "Низкие сроки поставки",
      },
      {
        icon: index_page_slide_1_icon_3,
        text: "Низкие цены",
      },
    ],
  },
  {
    imgUrl: sliderImage2,
    title: "Наши партнёры",
    subtitle:
      "Наша компания установила и поддерживает устойчивые партнерские и дилерские отношения более чем с двадцатью заводами-производителями и свыше чем с 300 предприятиями-потребителями России.",
    features: [
      {
        icon: index_page_slide_2_icon,
        text: "Предприятия строительства",
      },
      {
        icon: index_page_slide_2_icon,
        text: "Электроэнергетики",
      },
      {
        icon: index_page_slide_2_icon,
        text: "Нефтегазовый и горнодобывающий комплексы",
      },
      {
        icon: index_page_slide_2_icon,
        text: "Черная и цветная металлургия",
      },
      {
        icon: index_page_slide_2_icon,
        text: "Сырьевая и химическая промышленности",
      },
      {
        icon: index_page_slide_2_icon,
        text: "Машиностроения",
      },
      {
        icon: index_page_slide_2_icon,
        text: "Сельское хозяйство и коммунальные сферы",
      },
      {
        icon: index_page_slide_2_icon,
        text: "IT-компании",
      },
    ],
  },
  {
    imgUrl: sliderImage2,
    title: "Наши возможности",
    subtitle:
      "Широчайший ассортимент оборудования НКУ с комплектующими отечественного и импортного производства, включающий более 3 000 наименований, по конкурентоспособным ценам.",
    features: [
      {
        icon: index_page_slide_3icon1,
        text: "Комплексные поставки оборудования",
      },
      {
        icon: index_page_slide_3icon2,
        text: "Опыт в разработке и наладке",
      },
      {
        icon: index_page_slide_3icon3,
        text: "Изготовление изделий из пластика",
      },
    ],
  },
]
