import { FC } from "react"

import { IIcon } from "@types"

export const ApartmentIcon: FC<IIcon> = ({ width = 24, height = 22, className }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} aria-label="E-mail" className={className}>
      <path d="M19.1998 14.6006H16.7998V17.0006H19.1998V14.6006Z" fill="#93C01F" />
      <path d="M19.1998 9.80078H16.7998V12.2008H19.1998V9.80078Z" fill="#93C01F" />
      <path
        d="M12 5.00023V0.200195H0V21.8002H24V5.00023H12ZM4.79998 19.4002H2.40002V17.0002H4.80003V19.4002H4.79998ZM4.79998 14.6002H2.40002V12.2002H4.80003V14.6002H4.79998ZM4.79998 9.80021H2.40002V7.40019H4.80003V9.80021H4.79998ZM4.79998 5.00023H2.40002V2.60021H4.80003V5.00023H4.79998ZM9.60001 19.4002H7.19999V17.0002H9.60001V19.4002ZM9.60001 14.6002H7.19999V12.2002H9.60001V14.6002ZM9.60001 9.80021H7.19999V7.40019H9.60001V9.80021ZM9.60001 5.00023H7.19999V2.60021H9.60001V5.00023ZM21.6 19.4002H12V17.0002H14.4V14.6002H12V12.2002H14.4V9.80021H12V7.40019H21.6V19.4002Z"
        fill="#93C01F"
      />
    </svg>
  )
}
