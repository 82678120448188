import React from "react"

import {Breadcrumbs, HeadingText, PageHeader, Paragraph, Seo} from "@components"
import environmentalStations from "../../../public/environmental-stations.png"

export default function EnvironmentalStations() {
  return (
    <>
      <Seo
        title="Экологические станции"
        description="Экологические станции - это решение для непрерывного мониторинга уровня выбросов парниковых газов в
        окружающую среду. Станции могут отслеживать концентрации метана, углекислого газа и гексафторида серы.
        Кроме того, станции учитывают влияние различных метеорологических параметров: температуры, атмосферного
        давления, скорости и направления ветра, относительной влажности воздуха и УФ-излучения."
        image="/environmental-stations.png"
      />
      <Breadcrumbs items={[{label: "Экологический мониторинг"}, {label: "Экологические станции"}]}/>
      <PageHeader
        className="!bg-[url('/src/public/environmental-stations-header.png')]"
        title="Экологические станции"
      />
      <div className="x-paddings w-full bg-secondary-main pt-8 pb-[48%] md:pt-6">
        <div className="flex justify-between gap-5 md:flex-col md:gap-6">
          <div
            className="relative block min-h-full w-full max-w-[411px] lg:h-auto lg:min-w-[292px] md:min-h-[240px] md:max-w-full">
            <img
              src={environmentalStations}
              alt="Экологические станции"
              className="h-full w-full"
              sizes="(max-width: 767px) 100vw,
              (max-width: 1200px) 40vw,
              33vw"
            />
          </div>
          <div className="w-full space-y-4">
            <HeadingText className="-ml-5 md:-ml-[15px]">Назначение</HeadingText>
            <Paragraph>
              Экологические станции - это решение для непрерывного мониторинга уровня
              выбросов парниковых газов и загрязняющих веществ в окружающую среду.
              Кроме того, станции учитывают влияние различных метеорологических
              параметров: температуры, атмосферного давления, скорости и
              направления ветра, относительной влажности воздуха и УФ-излучения.
            </Paragraph>
            <Paragraph>
              Такой комплексный подход позволяет получить точную и объективную
              информацию об экологической обстановке, что обеспечивает возможность
              эффективного контроля и управления процессами, связанными
              с парниковыми газами и загрязняющими веществами.
            </Paragraph>
          </div>
        </div>
        <div className="mt-8 space-y-4 md:mt-6">
          <Paragraph variant="bold">Внедрение экологических станций позволит:</Paragraph>
          <Paragraph marker={true}>
            Автоматизировать сбор данных для формирования отчетов по парниковым газам;
          </Paragraph>
          <Paragraph marker={true}>
            Соблюдать требования законодательства в области парниковых газов;
          </Paragraph>
          <Paragraph marker={true}>
            Своевременно выявить экологические инциденты, такие как утечки метана;
          </Paragraph>
          <Paragraph marker={true}>
            Оптимизировать расходы предприятия за счет выявления источников загрязнения;
          </Paragraph>
          <Paragraph marker={true}>
            Отслеживать целевые показатели по сокращению выбросов парниковых газов.
          </Paragraph>
        </div>
      </div>
    </>
  )
}
