import gallerytab1image46 from "../public/gallery-tab-1-image-46.jpg"
import gallerytab1image46poster from "../public/gallery-tab-1-image-46-poster.jpg"
import gallerytab1image47 from "../public/gallery-tab-1-image-47.jpg"
import gallerytab1image47poster from "../public/gallery-tab-1-image-47-poster.jpg"
import gallerytab1image48 from "../public/gallery-tab-1-image-48.jpg"
import gallerytab1image48poster from "../public/gallery-tab-1-image-48-poster.jpg"
import gallerytab1image49 from "../public/gallery-tab-1-image-49.jpg"
import gallerytab1image49poster from "../public/gallery-tab-1-image-49-poster.jpg"
import gallerytab1image1 from "../public/gallery-tab-1-image-1.jpg"
import gallerytab1image1poster from "../public/gallery-tab-1-image-1-poster.jpg"
import gallerytab1image2 from "../public/gallery-tab-1-image-2.jpg"
import gallerytab1image2poster from "../public/gallery-tab-1-image-2-poster.jpg"
import gallerytab1image3 from "../public/gallery-tab-1-image-3.jpg"
import gallerytab1image3poster from "../public/gallery-tab-1-image-3-poster.jpg"
import gallerytab1image4 from "../public/gallery-tab-1-image-4.jpg"
import gallerytab1image4poster from "../public/gallery-tab-1-image-4-poster.jpg"
import gallerytab1image5 from "../public/gallery-tab-1-image-5.jpg"
import gallerytab1image5poster from "../public/gallery-tab-1-image-5-poster.jpg"
import gallerytab1image6 from "../public/gallery-tab-1-image-6.jpg"
import gallerytab1image6poster from "../public/gallery-tab-1-image-6-poster.jpg"
import gallerytab1image7 from "../public/gallery-tab-1-image-7.jpg"
import gallerytab1image7poster from "../public/gallery-tab-1-image-7-poster.jpg"
import gallerytab1image8 from "../public/gallery-tab-1-image-8.jpg"
import gallerytab1image8poster from "../public/gallery-tab-1-image-8-poster.jpg"
import gallerytab1image9 from "../public/gallery-tab-1-image-9.jpg"
import gallerytab1image9poster from "../public/gallery-tab-1-image-9-poster.jpg"
import gallerytab1image10 from "../public/gallery-tab-1-image-10.jpg"
import gallerytab1image10poster from "../public/gallery-tab-1-image-10-poster.jpg"
import gallerytab1image11 from "../public/gallery-tab-1-image-11.jpg"
import gallerytab1image11poster from "../public/gallery-tab-1-image-11-poster.jpg"
import gallerytab1image12 from "../public/gallery-tab-1-image-12.jpg"
import gallerytab1image12poster from "../public/gallery-tab-1-image-12-poster.jpg"
import gallerytab1image13 from "../public/gallery-tab-1-image-13.jpg"
import gallerytab1image13poster from "../public/gallery-tab-1-image-13-poster.jpg"
import gallerytab1image14 from "../public/gallery-tab-1-image-14.jpg"
import gallerytab1image14poster from "../public/gallery-tab-1-image-14-poster.jpg"
import gallerytab1image15 from "../public/gallery-tab-1-image-15.jpg"
import gallerytab1image15poster from "../public/gallery-tab-1-image-15-poster.jpg"
import gallerytab1image16 from "../public/gallery-tab-1-image-16.jpg"
import gallerytab1image16poster from "../public/gallery-tab-1-image-16-poster.jpg"
import gallerytab1image17 from "../public/gallery-tab-1-image-17.jpg"
import gallerytab1image17poster from "../public/gallery-tab-1-image-17-poster.jpg"
import gallerytab1image18 from "../public/gallery-tab-1-image-18.jpg"
import gallerytab1image18poster from "../public/gallery-tab-1-image-18-poster.jpg"
import gallerytab1image19 from "../public/gallery-tab-1-image-19.jpg";
import gallerytab1image19poster from "../public/gallery-tab-1-image-19-poster.jpg";
import gallerytab1image20 from "../public/gallery-tab-1-image-20.jpg";
import gallerytab1image20poster from "../public/gallery-tab-1-image-20-poster.jpg";
import gallerytab1image21 from "../public/gallery-tab-1-image-21.jpg";
import gallerytab1image21poster from "../public/gallery-tab-1-image-21-poster.jpg";
import gallerytab1image22 from "../public/gallery-tab-1-image-22.jpg";
import gallerytab1image22poster from "../public/gallery-tab-1-image-22-poster.jpg";
import gallerytab1image23 from "../public/gallery-tab-1-image-23.jpg";
import gallerytab1image23poster from "../public/gallery-tab-1-image-23-poster.jpg";
import gallerytab1image24 from "../public/gallery-tab-1-image-24.jpg";
import gallerytab1image24poster from "../public/gallery-tab-1-image-24-poster.jpg";
import gallerytab1image25 from "../public/gallery-tab-1-image-25.jpg";
import gallerytab1image25poster from "../public/gallery-tab-1-image-25-poster.jpg";
import gallerytab1image26 from "../public/gallery-tab-1-image-26.jpg";
import gallerytab1image26poster from "../public/gallery-tab-1-image-26-poster.jpg";
import gallerytab1image27 from "../public/gallery-tab-1-image-27.jpg";
import gallerytab1image27poster from "../public/gallery-tab-1-image-27-poster.jpg";
import gallerytab1image28 from "../public/gallery-tab-1-image-28.jpg";
import gallerytab1image28poster from "../public/gallery-tab-1-image-28-poster.jpg";
import gallerytab1image29 from "../public/gallery-tab-1-image-29.jpg";
import gallerytab1image29poster from "../public/gallery-tab-1-image-29-poster.jpg";
import gallerytab1image30 from "../public/gallery-tab-1-image-30.jpg";
import gallerytab1image30poster from "../public/gallery-tab-1-image-30-poster.jpg";
import gallerytab1image31 from "../public/gallery-tab-1-image-31.jpg";
import gallerytab1image31poster from "../public/gallery-tab-1-image-31-poster.jpg";
import gallerytab1image32 from "../public/gallery-tab-1-image-32.jpg";
import gallerytab1image32poster from "../public/gallery-tab-1-image-32-poster.jpg";
import gallerytab1image33 from "../public/gallery-tab-1-image-33.jpg";
import gallerytab1image33poster from "../public/gallery-tab-1-image-33-poster.jpg";
import gallerytab1image34 from "../public/gallery-tab-1-image-34.jpg";
import gallerytab1image34poster from "../public/gallery-tab-1-image-34-poster.jpg";
import gallerytab1image35 from "../public/gallery-tab-1-image-35.jpg";
import gallerytab1image35poster from "../public/gallery-tab-1-image-35-poster.jpg";
import gallerytab1image36 from "../public/gallery-tab-1-image-36.jpg";
import gallerytab1image36poster from "../public/gallery-tab-1-image-36-poster.jpg";
import gallerytab1image37 from "../public/gallery-tab-1-image-37.jpg";
import gallerytab1image37poster from "../public/gallery-tab-1-image-37-poster.jpg";
import gallerytab1image38 from "../public/gallery-tab-1-image-38.jpg";
import gallerytab1image38poster from "../public/gallery-tab-1-image-38-poster.jpg";
import gallerytab1image39 from "../public/gallery-tab-1-image-39.jpg";
import gallerytab1image39poster from "../public/gallery-tab-1-image-39-poster.jpg";
import gallerytab1image40 from "../public/gallery-tab-1-image-40.jpg";
import gallerytab1image40poster from "../public/gallery-tab-1-image-40-poster.jpg";
import gallerytab1image41 from "../public/gallery-tab-1-image-41.jpg";
import gallerytab1image41poster from "../public/gallery-tab-1-image-41-poster.jpg";
import gallerytab1image42 from "../public/gallery-tab-1-image-42.jpg";
import gallerytab1image42poster from "../public/gallery-tab-1-image-42-poster.jpg";
import gallerytab1image43 from "../public/gallery-tab-1-image-43.jpg";
import gallerytab1image43poster from "../public/gallery-tab-1-image-43-poster.jpg";
import gallerytab1image44 from "../public/gallery-tab-1-image-44.jpg";
import gallerytab1image44poster from "../public/gallery-tab-1-image-44-poster.jpg";
import gallerytab1image45 from "../public/gallery-tab-1-image-45.jpg";
import gallerytab1image45poster from "../public/gallery-tab-1-image-45-poster.jpg";

import galleryTab2image1 from "../public/gallery-tab-2-image-1.jpg"
import galleryTab2image1poster from "../public/gallery-tab-2-image-1-poster.jpg"
import galleryTab2image2 from "../public/gallery-tab-2-image-2.jpg"
import galleryTab2image2poster from "../public/gallery-tab-2-image-2-poster.jpg"
import galleryTab2image3 from "../public/gallery-tab-2-image-3.jpg"
import galleryTab2image3poster from "../public/gallery-tab-2-image-3-poster.jpg"
import galleryTab2image4 from "../public/gallery-tab-2-image-4.jpg"
import galleryTab2image4poster from "../public/gallery-tab-2-image-4-poster.jpg"
import galleryTab2image5 from "../public/gallery-tab-2-image-5.jpg"
import galleryTab2image5poster from "../public/gallery-tab-2-image-5-poster.jpg"
import galleryTab2image6 from "../public/gallery-tab-2-image-6.jpg"
import galleryTab2image6poster from "../public/gallery-tab-2-image-6-poster.jpg"
import galleryTab2image7 from "../public/gallery-tab-2-image-7.jpg"
import galleryTab2image7poster from "../public/gallery-tab-2-image-7-poster.jpg"

export type GalleryImage = { image: string; poster: string }

const galleryTab1Images: GalleryImage[] = [
  {
    image: gallerytab1image46,
    poster: gallerytab1image46poster,
  },
  {
    image: gallerytab1image47,
    poster: gallerytab1image47poster,
  },
  {
    image: gallerytab1image48,
    poster: gallerytab1image48poster,
  },
  {
    image: gallerytab1image49,
    poster: gallerytab1image49poster,
  },
  {
    image: gallerytab1image1,
    poster: gallerytab1image1poster,
  },
  {
    image: gallerytab1image2,
    poster: gallerytab1image2poster,
  },
  {
    image: gallerytab1image3,
    poster: gallerytab1image3poster,
  },
  {
    image: gallerytab1image4,
    poster: gallerytab1image4poster,
  },
  {
    image: gallerytab1image5,
    poster: gallerytab1image5poster,
  },
  {
    image: gallerytab1image6,
    poster: gallerytab1image6poster,
  },
  {
    image: gallerytab1image7,
    poster: gallerytab1image7poster,
  },
  {
    image: gallerytab1image8,
    poster: gallerytab1image8poster,
  },
  {
    image: gallerytab1image9,
    poster: gallerytab1image9poster,
  },
  {
    image: gallerytab1image10,
    poster: gallerytab1image10poster,
  },
  {
    image: gallerytab1image11,
    poster: gallerytab1image11poster,
  },
  {
    image: gallerytab1image12,
    poster: gallerytab1image12poster,
  },
  {
    image: gallerytab1image13,
    poster: gallerytab1image13poster,
  },
  {
    image: gallerytab1image14,
    poster: gallerytab1image14poster,
  },
  {
    image: gallerytab1image15,
    poster: gallerytab1image15poster,
  },
  {
    image: gallerytab1image16,
    poster: gallerytab1image16poster,
  },
  {
    image: gallerytab1image17,
    poster: gallerytab1image17poster,
  },
  {
    image: gallerytab1image18,
    poster: gallerytab1image18poster,
  },
  {
    image: gallerytab1image19,
    poster: gallerytab1image19poster,
  },
  {
    image: gallerytab1image20,
    poster: gallerytab1image20poster,
  },
  {
    image: gallerytab1image21,
    poster: gallerytab1image21poster,
  },
  {
    image: gallerytab1image22,
    poster: gallerytab1image22poster,
  },
  {
    image: gallerytab1image23,
    poster: gallerytab1image23poster,
  },
  {
    image: gallerytab1image24,
    poster: gallerytab1image24poster,
  },
  {
    image: gallerytab1image25,
    poster: gallerytab1image25poster,
  },
  {
    image: gallerytab1image26,
    poster: gallerytab1image26poster,
  },
  {
    image: gallerytab1image27,
    poster: gallerytab1image27poster,
  },
  {
    image: gallerytab1image28,
    poster: gallerytab1image28poster,
  },
  {
    image: gallerytab1image29,
    poster: gallerytab1image29poster,
  },
  {
    image: gallerytab1image30,
    poster: gallerytab1image30poster,
  },
  {
    image: gallerytab1image31,
    poster: gallerytab1image31poster,
  },
  {
    image: gallerytab1image32,
    poster: gallerytab1image32poster,
  },
  {
    image: gallerytab1image33,
    poster: gallerytab1image33poster,
  },
  {
    image: gallerytab1image34,
    poster: gallerytab1image34poster,
  },
  {
    image: gallerytab1image35,
    poster: gallerytab1image35poster,
  },
  {
    image: gallerytab1image36,
    poster: gallerytab1image36poster,
  },
  {
    image: gallerytab1image37,
    poster: gallerytab1image37poster,
  },
  {
    image: gallerytab1image38,
    poster: gallerytab1image38poster,
  },
  {
    image: gallerytab1image39,
    poster: gallerytab1image39poster,
  },
  {
    image: gallerytab1image40,
    poster: gallerytab1image40poster,
  },
  {
    image: gallerytab1image41,
    poster: gallerytab1image41poster,
  },
  {
    image: gallerytab1image42,
    poster: gallerytab1image42poster,
  },
  {
    image: gallerytab1image43,
    poster: gallerytab1image43poster,
  },
  {
    image: gallerytab1image44,
    poster: gallerytab1image44poster,
  },
  {
    image: gallerytab1image45,
    poster: gallerytab1image45poster,
  },
]

const galleryTab2Images: GalleryImage[] = [
  {
    image: galleryTab2image1,
    poster: galleryTab2image1poster,
  },
  {
    image: galleryTab2image2,
    poster: galleryTab2image2poster,
  },
  {
    image: galleryTab2image3,
    poster: galleryTab2image3poster,
  },
  {
    image: galleryTab2image4,
    poster: galleryTab2image4poster,
  },
  {
    image: galleryTab2image5,
    poster: galleryTab2image5poster,
  },
  {
    image: galleryTab2image6,
    poster: galleryTab2image6poster,
  },
  {
    image: galleryTab2image7,
    poster: galleryTab2image7poster,
  },
]

export { galleryTab1Images, galleryTab2Images }
