import React from "react"

import yashchikiUpravleniyaYA5000RUSM5000 from "../../../../public/yashchiki-upravleniya-YA5000-RUSM5000.jpg"
import { Breadcrumbs, HeadingText, PageHeader, Paragraph, Seo, Template } from "@components"
import { purposes, structureSchema } from "./constants"

export default function YA5000RUSM5000() {
  return (
    <>
      <Seo
        title="Ящики управления Я5000, РУСМ5000"
        description="Предназначены для управления асинхронными электродвигателями с короткозамкнутым ротором мощностью до 75 кВт., работающими в продолжительном, кратковременном или повторно-кратковременном режимах. Ящики управления РУСМ5000 применяются в помещениях с высокой влажностью и изготавливаются в корпусах со степенью защиты IР54"
        image="/yashchiki-upravleniya-YA5000-RUSM5000.jpg"
      />
      <Breadcrumbs
        items={[{ label: "Шкафы управления и распределения" }, { label: "Ящики управления Я5000, РУСМ5000" }]}
      />
      <PageHeader bgSecondary title="Ящики управления Я5000, РУСМ5000" />
      <Template
        className="pb-[3%]"
        equipmentImage={
          <img
            src={yashchikiUpravleniyaYA5000RUSM5000}
            width={218}
            height={266}
            alt="Ящики управления Я5000, РУСМ5000"
          />
        }
      >
        <HeadingText>Назначение</HeadingText>
        <div className="template-container">
          {purposes.map((item, index) => (
            <Paragraph key={item} marker={index > 3} variant={index === 3 ? "bold" : "normal"} children={item} />
          ))}
        </div>
        <HeadingText tag="h3">Структура условного обозначения</HeadingText>
        <div className="template-container border-none">
          {structureSchema.map(({ src, height, alt }, index) => (
            <>
              <img src={src} width={580} height={height} alt={alt} className="w-full" key={alt} />
              {index === 0 && (
                <p className="pb-2 text-sm text-gray-300 md:text-xs" key={src}>
                  * Примечание: - указывается на второй фидер в нереверсивных двухфидерных ящиках с одним автоматическим
                  выключателем на два фидера
                </p>
              )}
            </>
          ))}
        </div>
      </Template>
    </>
  )
}
