const purposes = [
  "Ящики управления типа Я5000, (РУСМ5000, ЩУ5000) предназначены для управления асинхронными электродвигателями с короткозамкнутым ротором мощностью до 75 кВт., работающими в продолжительном, кратковременном или повторно-кратковременном режимах. Ящики управления РУСМ5000 применяются в помещениях с высокой влажностью и изготавливаются в корпусах со степенью защиты IР54.",
  "Соответствуют ГОСТ Р51321.1-2000.",
  "Ящики управления изготавливаются в металлических боксах с монтажной панелью. Степень защиты в зависимости от типа ящика: IP31 или IP54.",
  "Ящики комплектуются в соответствии с обозначением и типовым индексом:",
  "автоматическими выключателями",
  "пускателями",
  "тепловыми реле",
  "светосигнальной арматурой",
  "аппаратами управления (кнопки, переключатели)",
]

const structureSchema: { src: string; height: number; alt: string }[] = [
  {
    src: "/yashchiki-upravleniya-YA5000-RUSM5000_schema-1.jpg",
    height: 408,
    alt: "Структура условного обозначения Я5000, РУСМ5000",
  },
  {
    src: "/yashchiki-upravleniya-YA5000-RUSM5000_schema-2.jpg",
    height: 210,
    alt: "Структура условного обозначения Я(РУСМ)5110",
  },
  {
    src: "/yashchiki-upravleniya-YA5000-RUSM5000_schema-3.jpg",
    height: 218,
    alt: "Структура условного обозначения Я(РУСМ)5111",
  },
  {
    src: "/yashchiki-upravleniya-YA5000-RUSM5000_schema-4.jpg",
    height: 246,
    alt: "Структура условного обозначения Я(РУСМ)5411",
  },
  {
    src: "/yashchiki-upravleniya-YA5000-RUSM5000_schema-5.jpg",
    height: 187,
    alt: "Структура условного обозначения Я(РУСМ)5113",
  },
  {
    src: "/yashchiki-upravleniya-YA5000-RUSM5000_schema-6.jpg",
    height: 224,
    alt: "Структура условного обозначения Я(РУСМ)5141",
  },
  {
    src: "/yashchiki-upravleniya-YA5000-RUSM5000_schema-7.jpg",
    height: 297,
    alt: "Структура условного обозначения Я(РУСМ)5115",
  },
  {
    src: "/yashchiki-upravleniya-YA5000-RUSM5000_schema-8.jpg",
    height: 308,
    alt: "Структура условного обозначения Я(РУСМ)5441",
  },
  {
    src: "/yashchiki-upravleniya-YA5000-RUSM5000_schema-9.jpg",
    height: 182,
    alt: "Структура условного обозначения Я(РУСМ)5131",
  },
  {
    src: "/yashchiki-upravleniya-YA5000-RUSM5000_schema-10.jpg",
    height: 293,
    alt: "Структура условного обозначения Я(РУСМ)5125",
  },
  {
    src: "/yashchiki-upravleniya-YA5000-RUSM5000_schema-11.jpg",
    height: 279,
    alt: "Структура условного обозначения Я(РУСМ)5135",
  },
]

export { purposes, structureSchema }
