import React from "react"

import shkafyVvodaABiZU from "../../../../public/Shkafy-vvoda-AB-i-ZU.jpg"
import { Breadcrumbs, CabinetsContent, PageHeader, Seo, Template } from "@components"
import { analogues, characteristics } from "./constants"

export default function ABZUCabinets() {
  return (
    <>
      <Seo
        title="Шкафы ввода АБ и ЗУ"
        description="Используются для бесперебойного питания оперативных цепей управления, защиты и автоматики электростанций, подстанций, электромагнитов коммутационных аппаратов, аварийного освещения и прочих объектов энергетики"
        image="/Shkafy-vvoda-AB-i-ZU.jpg"
      />
      <Breadcrumbs items={[{ label: "Шкафы управления и распределения" }, { label: "Шкафы ввода АБ и ЗУ" }]} />
      <PageHeader bgSecondary title="Шкафы ввода АБ и ЗУ" />
      <Template
        equipmentImage={<img src={shkafyVvodaABiZU} width={243} height={356} alt="Шкаф ввода АБ и ЗУ" />}
        characteristics={characteristics}
        analogues={analogues}
      >
        <CabinetsContent isDefaultVariant={false} />
      </Template>
    </>
  )
}
