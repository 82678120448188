import React from 'react';
import {Routes, Route} from 'react-router-dom';

// Импорты страниц
import HomePage from './index';
import Page404 from './404';
import Catalogue from './catalogue';
import Certificate from './certificate';
import Contacts from './contacts';
import Gallery from './gallery';
import News from './news';
import VideoGallery from './video-gallery';

// Akusticheskiy Kontrol
import AkusticheskiyKontrolV1 from './akusticheskiy-kontrol/izmeritelnyy-komplekt-v1';
import AkusticheskiyKontrolV2 from './akusticheskiy-kontrol/izmeritelnyy-komplekt-v2';
import SistemaAkusticheskogoKontrolyaEcho from './akusticheskiy-kontrol/sistema-akusticheskogo-kontrolya-echo';

// Bloki Pitaniya
import BlokiPitaniaACDC from './bloki-pitaniya/istochnik-pitaniya-AC-DC';
import StanciyaBistroyZaryadki from './bloki-pitaniya/stanciya-bystroy-zaryadki';

// Environmental Monitoring
import EnvironmentalStation from './environmental-monitoring/environmental-monitoring-station';
import EnvironmentalStations from './environmental-monitoring/environmental-stations';

// Izgotovlenie Plastikovykh Korpusov
import IzgotovleniePlastikovykhKorpusov from './izgotovlenie-plastikovykh-korpusov-i-detaley';
import DizaynKonstrukcii from './izgotovlenie-plastikovykh-korpusov-i-detaley/dizayn-i-razrabotka-konstruktsii';
import LitePodDavleniem from './izgotovlenie-plastikovykh-korpusov-i-detaley/lite-plastmass-pod-davleniem';
import Prototipirovanie from './izgotovlenie-plastikovykh-korpusov-i-detaley/prototipirovanie-i-lite-v-siliconovye-formy';
import SborkaPokraska from './izgotovlenie-plastikovykh-korpusov-i-detaley/sborka-i-pokraska-gotovykh-izdeliy';
import VakuumnoeFormovanie from './izgotovlenie-plastikovykh-korpusov-i-detaley/vakuumnoe-formovanie-listovykh-materialov';


// Shkafy Upravleniya Raspredeleniya
import PultyPRP from './shkafy-upravleniya-raspredeleniya/nku-dlya-avtomatizatsii/pulty-upravleniya-PRP';
import PultyElectroprivoda from './shkafy-upravleniya-raspredeleniya/nku-dlya-avtomatizatsii/pulty-upravleniya-electroprivoda-zadvizhek';
import ShchitkiOsvetitelnye from './shkafy-upravleniya-raspredeleniya/nku-dlya-avtomatizatsii/shchitki-osvetitelnye';
import ShchityStantsiiUpravleniya from './shkafy-upravleniya-raspredeleniya/nku-dlya-avtomatizatsii/shchity-stantsii-upravleniya';
import ShkafyAvariynogoOsv from './shkafy-upravleniya-raspredeleniya/nku-dlya-avtomatizatsii/shkafy-avariynogo-osveshcheniya';
import AvtoVklyuchenieRezerva from './shkafy-upravleniya-raspredeleniya/nku-dlya-avtomatizatsii/ustroystva-avtomaticheskogo-vklyucheniya-rezerva';

// Shchity Peremennogo Toka
import RT30_88 from './shkafy-upravleniya-raspredeleniya/shchity-peremennogo-toka/RT30-88';
import RT30_88M from './shkafy-upravleniya-raspredeleniya/shchity-peremennogo-toka/RT30-88M';
import GlavnyeRaspredelitelnyeShchity from './shkafy-upravleniya-raspredeleniya/shchity-peremennogo-toka/glavnye-raspredelitelnye-shchity';
import RaspredelitelnyeShchitySHCHO70 from './shkafy-upravleniya-raspredeleniya/shchity-peremennogo-toka/raspredelitelnye-shchity-SHCHO-70';
import ShchitySobstvennykhNuzhd from './shkafy-upravleniya-raspredeleniya/shchity-peremennogo-toka/shchity-sobstvennykh-nuzhd';
import ShkafyPitaniyaIUpravleniyaElectrodvigatelyami from './shkafy-upravleniya-raspredeleniya/shchity-peremennogo-toka/shkafy-pitaniya-i-upravleniya-electrodvigatelyami';
import ShkafyRaspredelitelnyeNizkogoNapryazheniya from './shkafy-upravleniya-raspredeleniya/shchity-peremennogo-toka/shkafy-raspredelitelnye-nizkogo-napryazheniya';

// Shkafy Obshego Naznacheniya
import ShkafyVvodaABP from './shkafy-upravleniya-raspredeleniya/shkafy-obshego-naznacheniya/shkafy-vvoda-ABP';
import YashchikiSilovyeYAR from './shkafy-upravleniya-raspredeleniya/shkafy-obshego-naznacheniya/yashchiki-silovye-YAR';
import YashchikiUpravleniyaYA5000RUSM5000 from './shkafy-upravleniya-raspredeleniya/shkafy-obshego-naznacheniya/yashchiki-upravleniya-YA5000-RUSM5000';
import YashchikiUpravleniyaOsvesheniem from './shkafy-upravleniya-raspredeleniya/shkafy-obshego-naznacheniya/yashchiki-upravleniya-osvesheniem';

// Shkafy Sobstvennykh Nuzhd
import ShkafyPitaniyaSolenoidov from './shkafy-upravleniya-raspredeleniya/shkafy-sobstvennykh-nuzhd/shkafy-pitaniya-solenoidov';
import ShkafyUpravleniyaRazeditelyami from './shkafy-upravleniya-raspredeleniya/shkafy-sobstvennykh-nuzhd/shkafy-upravleniya-razedinitelyami';

// Shkafy Vtorichnoy Komutatsii Naruzhnoy Ustanovki
import ShkafyKleemnykhZazhimov from './shkafy-upravleniya-raspredeleniya/shkafy-vtorichnoy-komutatsii-naruzhnoy-ustanovki/shkafy-kleemnykh-zazhimov';
import ShkafyObogrevaVyklyuchateley from './shkafy-upravleniya-raspredeleniya/shkafy-vtorichnoy-komutatsii-naruzhnoy-ustanovki/shkafy-obogreva-vyklyuchateley';
import ShkafyPromezhutochnykhZazhimov from './shkafy-upravleniya-raspredeleniya/shkafy-vtorichnoy-komutatsii-naruzhnoy-ustanovki/shkafy-promezhutochnykh-zazhimov';
import ShkafyZazhimovTransformatorov from './shkafy-upravleniya-raspredeleniya/shkafy-vtorichnoy-komutatsii-naruzhnoy-ustanovki/shkafy-zazhimov-transformatorov';
import ShkafyZazhimovZashchityShin from './shkafy-upravleniya-raspredeleniya/shkafy-vtorichnoy-komutatsii-naruzhnoy-ustanovki/shkafy_zazhimov_zashchity_shin';

// SOPT
import NizkovoltnyeKomplektnyeUstroystva from './shkafy-upravleniya-raspredeleniya/sopt/nizkovoltnye-komplektnye-ustroystva';
import OBRElektricheskie from './shkafy-upravleniya-raspredeleniya/sopt/obr';
import ShchityPostoyannogoToka from './shkafy-upravleniya-raspredeleniya/sopt/shchity-postoyannogo-toka';
import ShkafyOperativnogoToka from './shkafy-upravleniya-raspredeleniya/sopt/shkafy-operativnogo-toka';
import ShkafyOtkhodyashchikhLiniy from './shkafy-upravleniya-raspredeleniya/sopt/shkafy-otkhodyashchikh-liniy';
import ShkafyRaspredeleniyaOperativnogoToka from './shkafy-upravleniya-raspredeleniya/sopt/shkafy-raspredeleniya-operativnogo-toka';
import ShkafyVvodaABZU from './shkafy-upravleniya-raspredeleniya/sopt/shkafy-vvoda-ab-zu';
import ShkafyVvodaSektsionirovaniya from './shkafy-upravleniya-raspredeleniya/sopt/shkafy-vvoda-sektsionirovania';

// Uchet i Raspredelenie Electroenergii
import PunktyRaspredelitelnyePR from './shkafy-upravleniya-raspredeleniya/uchet-i-raspredeleneie-electroenergii/punkty-raspredelitelnye-PR';
import ShchitkiEtazhnyeSCHE from './shkafy-upravleniya-raspredeleniya/uchet-i-raspredeleneie-electroenergii/shchitki-etazhnye-SHCHE';
import ShchityUchetaRaspredelitelniyaElectroenergii from './shkafy-upravleniya-raspredeleniya/uchet-i-raspredeleneie-electroenergii/shchity-ucheta-raspredelitelniya-electroenergii';
import ShkafyRaspredelitelnyeSilovyeSHRS from './shkafy-upravleniya-raspredeleniya/uchet-i-raspredeleneie-electroenergii/shkafy-raspredelitelnye-silovye-SHRS';
import VRU1_11_10 from './shkafy-upravleniya-raspredeleniya/uchet-i-raspredeleneie-electroenergii/vvodno-raspredelitelnye-ustroystva-VRU-1/VRU-1-11-10';
import VRU1_13_20 from './shkafy-upravleniya-raspredeleniya/uchet-i-raspredeleneie-electroenergii/vvodno-raspredelitelnye-ustroystva-VRU-1/VRU-1-13-20';
import VRU1_17_70 from './shkafy-upravleniya-raspredeleniya/uchet-i-raspredeleneie-electroenergii/vvodno-raspredelitelnye-ustroystva-VRU-1/VRU-1-17-70';
import VRU1_21_10 from './shkafy-upravleniya-raspredeleniya/uchet-i-raspredeleneie-electroenergii/vvodno-raspredelitelnye-ustroystva-VRU-1/VRU-1-21-10';
import VRU1_28_65 from './shkafy-upravleniya-raspredeleniya/uchet-i-raspredeleneie-electroenergii/vvodno-raspredelitelnye-ustroystva-VRU-1/VRU-1-28-65';
import VRU1_41_00 from './shkafy-upravleniya-raspredeleniya/uchet-i-raspredeleneie-electroenergii/vvodno-raspredelitelnye-ustroystva-VRU-1/VRU-1-41-00';
import VRU1_47_00 from './shkafy-upravleniya-raspredeleniya/uchet-i-raspredeleneie-electroenergii/vvodno-raspredelitelnye-ustroystva-VRU-1/VRU-1-47-00';
import VRU1_48_03 from './shkafy-upravleniya-raspredeleniya/uchet-i-raspredeleneie-electroenergii/vvodno-raspredelitelnye-ustroystva-VRU-1/VRU-1-48-03';
import VRU1_50_00 from './shkafy-upravleniya-raspredeleniya/uchet-i-raspredeleneie-electroenergii/vvodno-raspredelitelnye-ustroystva-VRU-1/VRU-1-50-00';

// Дополнительные страницы Shkafy Upravleniya
// Добавьте аналогично остальные страницы

function AppRoutes() {
    return (
        <Routes>
                        {/* Главная */}
                        <Route path="/" element={<HomePage />} />

                        {/* Catalogue */}
                        <Route path="/catalogue" element={<Catalogue />} />

                        {/* Certificate */}
                        <Route path="/certificate" element={<Certificate />} />

                        {/* Contacts */}
                        <Route path="/contacts" element={<Contacts />} />

                        {/* Gallery */}
                        <Route path="/gallery" element={<Gallery />} />

                        {/* News */}
                        <Route path="/news" element={<News />} />

                        {/* Video Gallery */}
                        <Route path="/video-gallery" element={<VideoGallery />} />

                        {/* Akusticheskiy Kontrol */}
                        <Route path="/izmeritelnyy-komplekt-v1" element={<AkusticheskiyKontrolV1 />} />
                        <Route path="/izmeritelnyy-komplekt-v2" element={<AkusticheskiyKontrolV2 />} />
                        <Route path="/sistema-akusticheskogo-kontrolya-echo" element={<SistemaAkusticheskogoKontrolyaEcho />} />

                        {/* Bloki Pitaniya */}
                        <Route path="/istochnik-pitaniya-AC-DC" element={<BlokiPitaniaACDC />} />
                        <Route path="/stanciya-bystroy-zaryadki" element={<StanciyaBistroyZaryadki />} />

                        {/* Izgotovlenie Plastikovykh Korpusov */}
                        <Route path="/izgotovlenie-plastikovykh-korpusov-i-detaley" element={<IzgotovleniePlastikovykhKorpusov />} />
                        <Route path="/dizayn-i-razrabotka-konstruktsii" element={<DizaynKonstrukcii />} />
                        <Route path="/lite-plastmass-pod-davleniem" element={<LitePodDavleniem />} />
                        <Route path="/prototipirovanie-i-lite-v-siliconovye-formy" element={<Prototipirovanie />} />
                        <Route path="/sborka-i-pokraska-gotovykh-izdeliy" element={<SborkaPokraska />} />
                        <Route path="/vakuumnoe-formovanie-listovykh-materialov" element={<VakuumnoeFormovanie />} />

                        {/* Environmental Monitoring */}
                        <Route path="/environmental-monitoring-station" element={<EnvironmentalStation />} />
                        <Route path="/environmental-stations" element={<EnvironmentalStations />} />

                        {/* Shkafy Upravleniya Raspredeleniya */}
                        <Route path="/pulty-upravleniya-PRP" element={<PultyPRP />} />
                        <Route path="/pulty-upravleniya-electroprivoda-zadvizhek" element={<PultyElectroprivoda />} />
                        <Route path="/shchitki-osvetitelnye" element={<ShchitkiOsvetitelnye />} />
                        <Route path="/shchity-stantsii-upravleniya" element={<ShchityStantsiiUpravleniya />} />
                        <Route path="/shkafy-avariynogo-osveshcheniya" element={<ShkafyAvariynogoOsv />} />
                        <Route path="/ustroystva-avtomaticheskogo-vklyucheniya-rezerva" element={<AvtoVklyuchenieRezerva />} />

                        <Route path="/RT30-88" element={<RT30_88 />} />
                        <Route path="/RT30-88M" element={<RT30_88M />} />
                        <Route path="/glavnye-raspredelitelnye-shchity" element={<GlavnyeRaspredelitelnyeShchity />} />
                        <Route path="/raspredelitelnye-shchity-SHCHO-70" element={<RaspredelitelnyeShchitySHCHO70 />} />
                        <Route path="/shchity-sobstvennykh-nuzhd" element={<ShchitySobstvennykhNuzhd />} />
                        <Route path="/shkafy-pitaniya-i-upravleniya-electrodvigatelyami" element={<ShkafyPitaniyaIUpravleniyaElectrodvigatelyami />} />
                        <Route path="/shkafy-raspredelitelnye-nizkogo-napryazheniya" element={<ShkafyRaspredelitelnyeNizkogoNapryazheniya />} />
                        <Route path="/shkafy-vvoda-ABP" element={<ShkafyVvodaABP />} />
                        <Route path="/yashchiki-silovye-YAR" element={<YashchikiSilovyeYAR />} />
                        <Route path="/yashchiki-upravleniya-YA5000-RUSM5000" element={<YashchikiUpravleniyaYA5000RUSM5000 />} />
                        <Route path="/yashchiki-upravleniya-osvesheniem" element={<YashchikiUpravleniyaOsvesheniem />} />
                        <Route path="/shkafy-pitaniya-solenoidov" element={<ShkafyPitaniyaSolenoidov />} />
                        <Route path="/shkafy-upravleniya-razeditionallyami" element={<ShkafyUpravleniyaRazeditelyami />} />
                        <Route path="/shkafy-kleemnykh-zazhimov" element={<ShkafyKleemnykhZazhimov />} />
                        <Route path="/shkafy-obogreva-vyklyuchateley" element={<ShkafyObogrevaVyklyuchateley />} />
                        <Route path="/shkafy-promezhutochnykh-zazhimov" element={<ShkafyPromezhutochnykhZazhimov />} />
                        <Route path="/shkafy-zazhimov-transformatorov" element={<ShkafyZazhimovTransformatorov />} />
                        <Route path="/shkafy_zazhimov_zashchity_shin" element={<ShkafyZazhimovZashchityShin />} />
                        <Route path="/nizkovoltnye-komplektnye-ustroystva" element={<NizkovoltnyeKomplektnyeUstroystva />} />
                        <Route path="/obr" element={<OBRElektricheskie />} />
                        <Route path="/shchity-postoyannogo-toka" element={<ShchityPostoyannogoToka />} />
                        <Route path="/shkafy-operativnogo-toka" element={<ShkafyOperativnogoToka />} />
                        <Route path="/shkafy-otkhodyashchikh-liniy" element={<ShkafyOtkhodyashchikhLiniy />} />
                        <Route path="/shkafy-raspredeleniya-operativnogo-toka" element={<ShkafyRaspredeleniyaOperativnogoToka />} />
                        <Route path="/shkafy-vvoda-ab-zu" element={<ShkafyVvodaABZU />} />
                        <Route path="/shkafy-vvoda-sektsionirovania" element={<ShkafyVvodaSektsionirovaniya />} />
                        <Route path="/punkty-raspredelitelnye-PR" element={<PunktyRaspredelitelnyePR />} />
                        <Route path="/shchitki-etazhnye-SHCHE" element={<ShchitkiEtazhnyeSCHE />} />
                        <Route path="/shchity-ucheta-raspredelitelniya-electroenergii" element={<ShchityUchetaRaspredelitelniyaElectroenergii />} />
                        <Route path="/shkafy-raspredelitelnye-silovye-SHRS" element={<ShkafyRaspredelitelnyeSilovyeSHRS />} />
                        <Route path="/VRU-1-11-10" element={<VRU1_11_10 />} />
                        <Route path="/VRU-1-13-20" element={<VRU1_13_20 />} />
                        <Route path="/VRU-1-17-70" element={<VRU1_17_70 />} />
                        <Route path="/VRU-1-21-10" element={<VRU1_21_10 />} />
                        <Route path="/VRU-1-28-65" element={<VRU1_28_65 />} />
                        <Route path="/VRU-1-41-00" element={<VRU1_41_00 />} />
                        <Route path="/VRU-1-47-00" element={<VRU1_47_00 />} />
                        <Route path="/VRU-1-48-03" element={<VRU1_48_03 />} />
                        <Route path="/VRU-1-50-00" element={<VRU1_50_00 />} />


                        {/* 404 */}
                        <Route path="*" element={<Page404 />} />
                </Routes>
    );
}

export default AppRoutes;
