export const characteristics = [
  { param: "Габариты корпуса, мм", value: "120×120×70" },
  {
    param: "Масса модуля, г",
    value: "385",
  },
  {
    param: "Масса модуля с датчиками и USB накопителем, г",
    value: "455",
  },
  {
    param: "Рабочая температура, ˚С",
    value: "от 0 до +85",
  },
]
