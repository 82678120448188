import mainPageFeatureIcon1 from "../public/main-page-feature-icon-1.png"
import mainPageFeatureIcon2 from "../public/main-page-feature-icon-2.png"
import mainPageFeatureIcon3 from "../public/main-page-feature-icon-3.png"
import mainPageFeatureIcon4 from "../public/main-page-feature-icon-4.png"
import mainPageFeatureIcon5 from "../public/main-page-feature-icon-5.png"
import mainPageFeatureIcon6 from "../public/main-page-feature-icon-6.png"
import mainPageFeatureIcon7 from "../public/main-page-feature-icon-7.png"
import mainPageFeatureIcon8 from "../public/main-page-feature-icon-8.png"

const headingFeatures: { title: string; subtitle: string }[] = [
  {
    title: "Быстрое изготовление",
    subtitle: "Время изготовления от 3-х дней",
  },
  {
    title: "Доставка",
    subtitle: "Доставка по России и в страны СНГ",
  },
  {
    title: "Гарантия качества",
    subtitle: "Сертификаты соответствия ТР ТС",
  },
]

const serviceFeatures: { icon: string; description: string }[] = [
  {
    icon: mainPageFeatureIcon1,
    description: "Размеры помещения",
  },
  {
    icon: mainPageFeatureIcon2,
    description: "Подбор и замена оборудования на аналоги",
  },
  {
    icon: mainPageFeatureIcon3,
    description: "Степень пыле- и влагозащищенности IP: 31-65",
  },
  {
    icon: mainPageFeatureIcon4,
    description: "Дополнительные системы защиты",
  },
  {
    icon: mainPageFeatureIcon5,
    description: "Внутреннее разделение от вида 1 до вида 4b",
  },
  {
    icon: mainPageFeatureIcon6,
    description: "Механизмы, повышающие удобство эксплуатации",
  },
  {
    icon: mainPageFeatureIcon7,
    description: "Системы автоматизации, диспетчеризации и многое другое",
  },
  {
    icon: mainPageFeatureIcon8,
    description: "Разработка с запасом прочности или под повышенную нагрузку",
  },
]

export { headingFeatures, serviceFeatures }
