export const styles = {
  header: "flex h-[48px] w-full items-center bg-primary-main pl-5 text-lg text-secondary-main md:text-base-500",
  list: "flex items-center justify-between gap-5 py-2 border-t-[1px] border-gray-100",
  listItem1: "text-base md:text-sm",
  listItem2: "max-w-[70px] flex-none text-base-500 text-primary-dark",
  markerOutlined:
    "marker-rounded-fill ml-7 pl-[18px] before:h-2 before:w-2 before:border-[1px] before:border-primary-main before:bg-secondary-main md:ml-6",
  markerFilled:
    "marker-rounded-fill ml-[48px] pl-[14px] text-sm before:h-1 before:w-1 before:bg-secondary-dark md:ml-[44px] md:text-xs",
}
