import React from 'react';

import {Layout} from "@components";
import AppRouter from "./pages/AppRouter";
import {BrowserRouter as Router} from "react-router-dom";

function App() {
  return (
    <Router>
        <Layout>
          <AppRouter/>
        </Layout>
    </Router>
  );
}

export default App;
