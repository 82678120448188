export const requisites: string[] = [
  'Общество с ограниченной ответственностью "Завод электротехники и автоматики"',
  "Фактический Адрес: 428903, Чувашская Республика, г. Чебоксары, Лапсарский проезд, дом 2 литер В пом.48",
  "Юридический Адрес: 428903, Чувашская Республика, г. Чебоксары, Лапсарский проезд, дом 2 литер В пом.48",
  "Почтовый Адрес: 428027, г. Чебоксары, а/я37",
  "Телефоны: 8(8352) 27-08-36, 8(8352)38-08-36",
  "Email: zeta21@bk.ru",
  "ИНН/КПП 2130107615/213001001",
  "ОГРН 1122130010221",
  "Расчетный счет 40702810510530000639",
  "Филиал «Центральный» Банка ВТБ (ПАО) г. Москва",
  "Корреспондентский счет 30101810145250000411",
  "БИК банка 044525411",
  "ОКПО 13100163",
  "Генеральный директор: Сергеев Василий Аркадьевич",
]
