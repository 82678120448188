const analogues = ["ПСН", "РУСН", "ШРНН", "РУ НН", "РУНН", "ШРУЭ", "ШСН", "ВРУ", "КРУЗА-П", "Freecon"]

const purposes: { variant?: "bold"; marker?: boolean; text: string }[] = [
  {
    text: "Используются для питания оборудования энергией в системах собственных нужд (освещение, обогрев, приводы выключателей, насосы и др.) переменным током напряжением 0,4 кВ.",
  },
  {
    text: "Наши шкафы для питания и управления сертифицированы на соответствие требованиям ГОСТ Р 51321.1-2000, а также испытана на сейсмостойкость при сейсмических воздействиях интенсивностью 9 баллов по шкале MSK-64.",
  },
  {
    text: "Щиты собственных нужд переменного тока на напряжение 0,4 кВ выпускаются на полный ряд номинальных и ударных токов.",
  },
  {
    variant: "bold",
    text: "Щит собственных нужд (ЩСН) состоит:",
  },
  {
    marker: true,
    text: "Шкафы ввода",
  },
  {
    marker: true,
    text: "Шкаф секционирования с блоком АВР",
  },
  {
    marker: true,
    text: "Шкафы распределения",
  },
  {
    variant: "bold",
    text: "ЩСН обеспечивает селективную защиту от:",
  },
  {
    marker: true,
    text: "Однофазных замыканий",
  },
  {
    marker: true,
    text: "Межфазных замыканий",
  },
  {
    marker: true,
    text: "Перегрузки",
  },
  {
    text: "Конструктивно щиты выполняются в виде сборных шкафов из оцинкованной стали или могут быть сделаны из шкафов сварной конструкции. По заказу ЩСН выполняются в сейсмостойком исполнении до 9 баллов по MSK-64.",
  },
  {
    text: "ЩСН–0,4 кВ изготавливаются в виде щитов ячеечного типа с выкатными, втычными или стационарными автоматическими выключателями как отечественного, так и импортного производства.",
  },
  {
    text: "Щиты собственных нужд могут запитываться от одного или двух трансформаторов (возможны и другие варианты) мощностью от 63 кВА до 2500 кВА и могут быть размещены в один или два ряда. При двухрядном исполнении дополнительно могут доукомплектовываться шинным мостом. По способам подвода шин или кабелей реализуются все варианты.",
  },
]

const functionalities = [
  "Выдача дискретных сигналов о положении автоматических выключателей, сигналов неисправности и аналоговых сигналов контролируемых параметров.",
  "ЩСН имеет локальную микропроцессорную систему мониторинга и контроля, позволяющую производить интеграцию ЩСН по стандартному протоколу в АСУ ТП любого энергообъекта. Система позволяет считывать значения токов и напряжений на вводах и секциях ЩСН, положения автоматических выключателей, сигналы аварии и неисправности, а также управлять выключателями по командам АСУ. Система мониторинга обеспечивает поддержку передачи данных по протоколу МЭК 61850 (опция).",
  "В щите реализуется система автоматического ввода резерва (АВР). При нарушении питания ТСН одной из секций (снижении или превышении установленного уровня напряжения, обрыве одной или нескольких фаз, обрыве нейтрального проводника) автоматика ЩСН осуществляет автоматический ввод резервного питания (АВР) по схеме явного или неявного резервирования.",
]

const composition = [
  "Шкафы ввода",
  "Шкафы секционирования",
  "Шкафы отходящих линий",
  "Шкафы управления и автоматики",
  "Система мониторинга и контроля включает в себя модули ввода/вывода аналоговых и дискретных сигналов и контроллер, объединённые последовательным интерфейсом RS.485, устанавливаемые в отсеки шкафов щита ЩСН.",
]

export { analogues, composition, functionalities, purposes }
