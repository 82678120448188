import React from "react"

import { Breadcrumbs, PageHeader, Paragraph, Seo } from "@components"

import { breadcrumbItems, stages } from "./constants"
import { styles } from "./styles"

export default function DesignAndConstructionDevelopment() {
  return (
    <>
      <Seo
        title="Дизайн и разработка конструкции"
        description="Завод электротехники и автоматики предлагает свои услуги в области разработки дизайна и конструкции будущего изделия по индивидуальному заданию заказчика. Специалисты нашей компании осуществляют работы по разработке дизайна будущего изделия как в 2D-эскизах, так и в  3D-моделях с учетом технических требований заказчика"
        keywords="Дизайн изделия в 2D-эскизах и 3D-моделях с учетом технических требований, заказать дизайн и разработка конструкции в Москве по всей России"
      />
      <Breadcrumbs items={breadcrumbItems} />
      <PageHeader title="Дизайн и разработка конструкции" />
      <div className="x-paddings bg-secondary-main pb-[30%] pt-8 xl:pb-[20%] md:pt-6">
        <Paragraph>
          Компания предлагает свои услуги в области разработки дизайна и конструкции будущего изделия по индивидуальному
          заданию заказчика. Специалисты нашей компании осуществляют работы по разработке дизайна будущего изделия как в
          2D-эскизах, так и в 3D-моделях с учетом технических требований заказчика. При этом предложенное решение
          является гарантированно выполнимым на производстве за счет учета технологии производства и предварительной
          конструкторской проработкой изделия.
        </Paragraph>
        <h2 className="my-6 text-xl lg:text-lg md:text-base-500">
          Основные этапы дизайна изделия и разработки конструкции
        </h2>
        <ul className={styles.stageBox}>
          {stages.map((stage) => (
            <li className={styles.stage} key={stage}>
              {stage}
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}
