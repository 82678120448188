import React from "react"

import stanciyaBysroyZaryadki from "../../../public/stanciya-bysroy-zaryadki.png"
import { Breadcrumbs, HeadingText, PageHeader, Paragraph, Seo, Template } from "@components"
import { characteristics } from "./constants"
import { styles } from "./styles"

export default function FastChargingStation() {
  const { leftCell, rightCell } = styles

  return (
    <>
      <Seo
        title="Станция быстрой зарядки"
        description="Станция предназначена для одновременной зарядки нескольких подключенных устройств. Станция оснащена четырьмя портами типа USB-A, четырьмя портами типа USB-C, и одним беспроводным интерфейсом"
        image="/stanciya-bysroy-zaryadki.png"
      />
      <Breadcrumbs items={[{ label: "Блоки питания" }, { label: "Станция быстрой зарядки" }]} />
      <PageHeader bgSecondary title="Станция быстрой зарядки" />
      <Template
        equipmentImage={
          <img src={stanciyaBysroyZaryadki} width={250} height={162} alt="Станция быстрой зарядки" />
        }
        characteristics={characteristics}
      >
        <HeadingText>Назначение</HeadingText>
        <div className="template-container">
          <Paragraph>
            Станция предназначена для одновременной зарядки нескольких подключенных устройств. Станция оснащена четырьмя
            портами типа USB-A, четырьмя портами типа USB-C, и одним беспроводным интерфейсом. Суммарная мощность 110
            Вт. Устройство оснащено монитором для отображения текущих параметров и предупреждений о возможных
            перегрузках. Устройство оснащено встроенными системами защит и аварийного отключения.
          </Paragraph>
          <Paragraph>
            Интерфейсы разделены на группы, внутри которых они соединены параллельно. Это вызывает разделение мощности
            при одновременном подключение устройств к интерфейсам одной группы. Пожалуйста, внимательно ознакомьтесь с
            характеристиками перед использованием.
          </Paragraph>
        </div>
        <HeadingText>Технические характеристики</HeadingText>
        <div className="template-container border-none px-0">
          <table className="w-full">
            <thead className="h-[34px] bg-primary-main text-left text-base-500 text-secondary-main md:text-sm-500">
              <tr>
                <th className="w-[30%] border-r-[1px] border-gray-100 pl-5 md:px-[15px]">Модуль/характеристика</th>
                <th className="w-[70%] pl-[30px] md:pl-[15px]">Описание/значение</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 border-b-[1px] border-gray-100">
              <tr>
                <td className={leftCell}>Модель</td>
                <td className={rightCell}>БЗ-9</td>
              </tr>
              <tr>
                <td className={leftCell}>Интерфейсы</td>
                <td className={rightCell}>USB-А - 4 шт., USB-C - 4 шт.</td>
              </tr>
              <tr>
                <td className={leftCell}>Суммарная мощность</td>
                <td className={rightCell}>110Вт</td>
              </tr>
              <tr>
                <td className={leftCell}>Беспроводной интерфейс</td>
                <td className={rightCell}>15Вт</td>
              </tr>
              <tr>
                <td className={leftCell}>Напряжение</td>
                <td className={rightCell}>АС 100-240В 60-60 Гц</td>
              </tr>
              <tr>
                <td className={leftCell}>А</td>
                <td className={rightCell}>
                  USB-1[QC3.0]: 5В3А/9В2А/12В1.5А
                  <br /> USB-5[PD]: 5В3А/9В3А/12В3А/15В3А/20В2.25А
                  <br /> USB-1[QC3.0]+USB-5[PD]: (18Вт+30Вт)
                </td>
              </tr>
              <tr>
                <td className={leftCell}>B</td>
                <td className={rightCell}>
                  USB-2[QC3.0]: 5В3А/9В2А/12В1.5А
                  <br /> USB-6[PD]: 5В3А/9В3А/12В2.5А/15В2А/20В1.5А
                  <br /> USB-2[QC3.0]+USB-6[PD]: (5В1.5А+5В1.5А)
                </td>
              </tr>
              <tr>
                <td className={leftCell}>C</td>
                <td className={rightCell}>
                  USB-3[QC3.0]: 5В3А/9В2А/12В1.5А
                  <br /> USB-7[PD]: 5В3А/9В2.22А/12В1.67А
                  <br /> USB-3[QC3.0]+USB-7[PD] (5В1.5А+5В1.5А)
                </td>
              </tr>
              <tr>
                <td className={leftCell}>D</td>
                <td className={rightCell}>
                  USB-4[QC3.0]: 5В3А/9В2А/12В1.5А
                  <br /> USB-8[PD]: 5В3А/9В2.22А/12В1.67А
                  <br /> USB-4[QC3.0]+USB-8[PD] (5В1.5А+5В1.5А)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Template>
    </>
  )
}
