export const headerNavLinks: { url: string; title: string }[] = [
  {
    url: "/",
    title: "Главная",
  },
  {
    url: "/news",
    title: "Новости",
  },
  {
    url: "/certificate",
    title: "Сертификат",
  },
  {
    url: "/gallery",
    title: "Фотогалерея",
  },
  // {
  //   url: "/video-gallery",
  //   title: "Видео",
  // },
  {
    url: "/catalogue",
    title: "Каталог",
  },
  {
    url: "/contacts",
    title: "Контакты",
  },
]
