import React from "react"

import shkafy_otkhodyashchikh_liniy from "../../../public/shkafy_otkhodyashchikh-liniy.jpg"
import { Breadcrumbs, CabinetsContent, PageHeader, Seo, Template } from "@components"

export default function SHOT() {
  const characteristics = [
    { param: "Номинальное напряжение", value: "220 Вт" },
    {
      param: "Количество секций",
      value: "4-6 шт.",
    },
  ]

  return (
    <>
      <Seo
        title="Шкафы отходящих линий"
        description="Используются для бесперебойного питания оперативных цепей управления, защиты и автоматики электростанций, подстанций, электромагнитов коммутационных аппаратов, аварийного освещения и прочих объектов энергетики"
        image="/shkafy_otkhodyashchikh-liniy.jpg"
      />
      <Breadcrumbs items={[{ label: "Шкафы управления и распределения" }, { label: "Шкафы отходящих линий" }]} />
      <PageHeader bgSecondary title="Шкафы отходящих линий" />
      <Template
        equipmentImage={
          <img src={shkafy_otkhodyashchikh_liniy} width={252} height={326} alt="Шкаф отходящих линий" />
        }
        characteristics={characteristics}
        analogues={["ШОЛ", "Шкаф распределения среднего уровня", "ШПТ", "ШРСУ"]}
      >
        <CabinetsContent />
      </Template>
    </>
  )
}
