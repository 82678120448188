import React from "react"

import obr_image from "../../../../public/obr-image.jpg"
import { Breadcrumbs, HeadingText, PageHeader, Paragraph, Seo, Template } from "@components"
import { analogues, breadcrumbItems, characteristics, functionalities, requirements } from "./constants"

export default function Index() {
  return (
    <>
      <Seo
        title="Шкафы питания цепей оперативной блокировки разъединителей (ОБР)"
        description="Обеспечивают функцию блокировки управления коммутационной аппаратурой на объектах. Заказать шкаф питания цепей оперативной блокировки разъединителей в России (Москве) по доступной цене"
        image="/obr-image.jpg"
      />
      <Breadcrumbs items={breadcrumbItems} />
      <PageHeader bgSecondary title="Шкафы питания цепей оперативной блокировки разъединителей (ОБР)" />
      <Template
        equipmentImage={
          <img
            src={obr_image}
            width={235}
            height={419}
            alt="Шкаф питания цепей оперативной блокировки разъединителей (ОБР)"
          />
        }
        characteristics={characteristics}
        analogues={analogues}
      >
        <HeadingText>Назначение</HeadingText>
        <div className="template-container">
          <Paragraph>Обеспечивают функцию блокировки управления коммутационной аппаратурой на объектах.</Paragraph>
        </div>
        <HeadingText tag="h3">Функциональные возможности шкафов ОБР</HeadingText>
        <div className="template-container">
          {functionalities.map((item) => (
            <Paragraph key={item} marker={true} children={item} />
          ))}
        </div>
        <HeadingText tag="h4">Требования к устройствам</HeadingText>
        <div className="template-container border-none">
          {requirements.map((item, index) => (
            <Paragraph key={item} marker={index < 4} children={item} />
          ))}
        </div>
      </Template>
    </>
  )
}
