const characteristics = [
  { param: "Номинальное напряжение", value: "220 Вт" },
  {
    param: "Количество секций",
    value: "4-6 шт.",
  },
]

const analogues: string[] = [
  "Шкаф ввода аккумуляторной батареи",
  "Шкаф ввода зарядного устройство",
  "ШВС",
  "Шкаф ввода и распределения",
]

export { analogues, characteristics }
