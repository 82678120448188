const analogues = ["ЩПТ", "РЩПТ"]

const characteristics = [
  { param: "Номинальное напряжение", value: "220 Вт" },
  {
    param: "Количество секций",
    value: "4-6 шт.",
  },
]

export { analogues, characteristics }
