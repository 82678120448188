import React from "react"

import environmentalMonitoringStation from '../../../public/environmental-monitoring-station.png'
import {Breadcrumbs, HeadingText, PageHeader, Paragraph, Seo, Template} from "@components"
import {characteristics} from "./constants"
import {styles} from "./styles"

export default function MeasuringSetV2() {
  const {leftCell, rightCell, markedCellText} = styles

  return (
    <>
      <Seo
        title="Измерительный комплект V2.0"
        description="Измерительный комплект V2.0 предназначено для акустического контроля энергетического оборудования с помощью использования технологий искусственного интеллекта (ИИ)"
        image="/izmeritelnyy-komplekt-v2.png"
      />
      <Breadcrumbs
        items={[{label: "Экологический мониторинг"}, { label: "Станция экологического мониторинга" }]}
      />
      <PageHeader
        className="!bg-[url('../../../public/environmental-monitoring-station.png')]"
        title="Экологические станции"
      />
      <Template
        equipmentImage={
          <>
            <img src={environmentalMonitoringStation} width={255} height={282} alt="Сенсор"/>
          </>
        }
        characteristics={characteristics}
      >
        <HeadingText>Технические характеристики</HeadingText>
        <div className="template-container border-none px-0">
          <Paragraph className="px-5 md:px-[15px]" variant="bold">
            <b>Измеряемые газы</b>
          </Paragraph>
          <table className="w-full">
            <thead
                className="h-[34px] bg-primary-main text-left text-base-500 text-secondary-main md:text-sm-500">
            <tr>
              <th className="w-[30%] pl-[20px] md:pl-[15px]">Газ</th>
              <th className="w-[30%] pl-[20px] md:pl-[15px]">Диапазон
                измерений
              </th>
              <th className="w-[20%] pl-[20px] md:pl-[15px]">Относительная погрешность, %</th>
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 border-b-[1px] border-gray-100">
            <tr>
              <td className={leftCell}>Азота диоксид (NO2)</td>
              <td className={leftCell}>
                0,01–10 мг/м3 <br/>
                0,1–30 мг/м3 <br/>
                10–500 мг/м3
              </td>
              <td className={leftCell}>
                ±25 <br/>
                ±15 <br/>
                ±10
              </td>
            </tr>
            <tr>
              <td className={leftCell}>Азота оксид (NO)</td>
              <td className={leftCell}>
                0,01–5 мг/м3 <br/>
                0,1–30 мг/м3 <br/>
                10–1000 мг/м3
              </td>
              <td className={leftCell}>
                ±25 <br/>
                ±15 <br/>
                ±10
              </td>
            </tr>
            <tr>
              <td className={leftCell}>Аммиак (NH3)</td>
              <td className={leftCell}>
                0,01–10 мг/м3 <br/>
                10–200 мг/м3 <br/>
                10–1500 мг/м3
              </td>
              <td className={leftCell}>
                ±25 <br/>
                ±15 <br/>
                ±10
              </td>
            </tr>
            <tr>
              <td className={leftCell}>Водород (H2)</td>
              <td className={leftCell}>
                0,001–1 об. доля, % <br/>
                0,01–4 об. доля, % <br/>
                1–100 об доля, %
              </td>
              <td className={leftCell}>
                ±15 <br/>
                ±10 <br/>
                ±5
              </td>
            </tr>
            <tr>
              <td className={leftCell}>Водород хлористый (HCl)</td>
              <td className={leftCell}>0,01–30 мг/м3</td>
              <td className={leftCell}>±25</td>
            </tr>
            <tr>
              <td className={leftCell}>Гелий (He)</td>
              <td className={leftCell}>1–100 об. доля, %</td>
              <td className={leftCell}>±20</td>
            </tr>
            <tr>
              <td className={leftCell}>Кислород (O2)</td>
              <td className={leftCell}>
                0,01–1 об. доля, % <br/>
                0,1–30 об. доля, % <br/>
                1–100 об. доля, %
              </td>
              <td className={leftCell}>
                ±10 <br/>
                ±5 <br/>
                ±1
              </td>
            </tr>
            <tr>
              <td className={leftCell}>Метан (CH4)</td>
              <td className={leftCell}>
                0,001–1 об. доля, % <br/>
                0,01–5 об. доля, % <br/>
                1–100 об. доля, %
              </td>
              <td className={leftCell}>
                ±10 <br/>
                ±10 <br/>
                ±5
              </td>
            </tr>
            <tr>
              <td className={leftCell}>Метанол (CH3 OH)</td>
              <td className={leftCell}>0,1–30 мг/м3</td>
              <td className={leftCell}>±20</td>
            </tr>
            <tr>
              <td className={leftCell}>Пропан (C3 H8)</td>
              <td className={leftCell}>0,001–2 об. доля, %</td>
              <td className={leftCell}>±10</td>
            </tr>
            <tr>
              <td className={leftCell}>Сероводород (H2 S)</td>
              <td className={leftCell}>
                0,01–3 мг/м3 <br/>
                0,1–30 мг/м3 <br/>
                1–200 мг/м3
              </td>
              <td className={leftCell}>
                ±20 <br/>
                ±10 <br/>
                ±10
              </td>
            </tr>
            <tr>
              <td className={leftCell}>Серы диоксид (SO2)</td>
              <td className={leftCell}>
                0,01–3 мг/м3 <br/>
                0,1–30 мг/м3
              </td>
              <td className={leftCell}>
                ±15 <br/>
                ±10
              </td>
            </tr>
            <tr>
              <td className={leftCell}>Углеводороды (С2–С10)</td>
              <td className={leftCell}>
                50–3000 мг/м3 <br/>
                0,05–1 об. доля, %
              </td>
              <td className={leftCell}>
                ±25 <br/>
                ±10
              </td>
            </tr>
            <tr>
              <td className={leftCell}>Углерода диоксид (СО2)</td>
              <td className={leftCell}>
                100–2000 мг/м3 <br/>
                0,01–5 об. доля, % <br/>
                1–100 об. доля, %
              </td>
              <td className={leftCell}>
                ±20 <br/>
                ±15 <br/>
                ±10
              </td>
            </tr>
            <tr>
              <td className={leftCell}>Углерода оксид (CO)</td>
              <td className={leftCell}>
                0,01–10 мг/м3 <br/>
                0,1–300 мг/м3 <br/>
                10–3000 мг/м3
              </td>
              <td className={leftCell}>
                ±20 <br/>
                ±10 <br/>
                ±10
              </td>
            </tr>
            <tr>
              <td className={leftCell}>Формальдегид (H2 CO)</td>
              <td className={leftCell}>0,1–30 мг/м3</td>
              <td className={leftCell}>±25</td>
            </tr>
            <tr>
              <td className={leftCell}>Хлор (Cl2)</td>
              <td className={leftCell}>
                0,01–3 мг/м3 <br/>
                0,1–30 мг/м3
              </td>
              <td className={leftCell}>
                ±20 <br/>
                ±10
              </td>
            </tr>
            <tr>
              <td className={leftCell}>Этанол (C2 H5 OH)</td>
              <td className={leftCell}>
                50–5000 мг/м3 <br/>
                0,01–3 об. доля, %
              </td>
              <td className={leftCell}>
                ±25 <br/>
                ±15
              </td>
            </tr>
            </tbody>
          </table>

          <Paragraph className="px-5 md:px-[15px]"
                     variant="bold"><b>Метеостанция</b></Paragraph>
          <table className="w-full">
            <thead
                className="h-[34px] bg-primary-main text-left text-base-500 text-secondary-main md:text-sm-500">
            <tr>
              <th className="w-[30%] pl-[20px] md:pl-[15px]">Параметр</th>
              <th className="w-[30%] pl-[20px] md:pl-[15px]">Диапазон
                измерений
              </th>
              <th className="w-[20%] pl-[20px] md:pl-[15px]">Шаг измерения</th>
              <th className="w-[20%] pl-[20px] md:pl-[15px]">Точность</th>
            </tr>
            </thead>
            <tbody
                className="divide-y divide-gray-100 border-b-[1px] border-gray-100">
            <tr>
              <td className={leftCell}>Скорость ветра</td>
              <td className={leftCell}>0-60 м/с</td>
              <td className={leftCell}>0,01 м/c</td>
              <td className={leftCell}>±0.3 м/с</td>
            </tr>
            <tr>
              <td className={leftCell}>Направление ветра</td>
              <td className={leftCell}>0-360°</td>
              <td className={leftCell}>0.1°</td>
              <td className={leftCell}>±2°</td>
            </tr>
            <tr>
              <td className={leftCell}>Температура воздуха</td>
              <td className={leftCell}>-40-60°C</td>
              <td className={leftCell}>0.01°C</td>
              <td className={leftCell}>±0.3°C</td>
            </tr>
            <tr>
              <td className={leftCell}>Относительная влажность воздуха</td>
              <td className={leftCell}>0-100%</td>
              <td className={leftCell}>0.01%</td>
              <td className={leftCell}>±3%</td>
            </tr>
            <tr>
              <td className={leftCell}>Атмосферное давление</td>
              <td className={leftCell}>300-1100 гПа</td>
              <td className={leftCell}>0.1 гПа</td>
              <td className={leftCell}>±0.5 гПа</td>
            </tr>
            <tr>
              <td className={leftCell}>УФ-излучение</td>
              <td className={leftCell}>0-2000 Вт/м2</td>
              <td className={leftCell}>1 Вт/м2</td>
              <td className={leftCell}>±3%</td>
            </tr>
            </tbody>
          </table>
        </div>
      </Template>
    </>
  )
}
