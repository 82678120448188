type Categories = {
  category: string
  links: {
    title: string
    url?: string
    nestedLinks?: {
      title: string
      url: string
    }[]
  }[]
}
export const categories: Categories[] = [
  {
    category: "Шкафы управления и распределения",
    links: [
      {
        title: "Системы оперативного постоянного тока (СОПТ)",
        nestedLinks: [
          {
            url: "/nizkovoltnye-komplektnye-ustroystva",
            title: "Низковольтные комплектные устройства (НКУ)",
          },
          {
            url: "/obr",
            title: "Шкафы питания цепей оперативной блокировки разъединителей (ОБР)",
          },
          {
            url: "/shkafy-raspredeleniya-operativnogo-toka",
            title: "Шкафы распределения оперативного тока (ШРОТ)",
          },
          {
            url: "/shkafy-vvoda-ab-zu",
            title: "Шкафы ввода АБ и ЗУ",
          },
          {
            url: "/shkafy-vvoda-sektsionirovania",
            title: "Шкафы ввода и секционирования",
          },
          {
            url: "/shchity-postoyannogo-toka",
            title: "Щиты постоянного тока (ЩПТ)",
          },
          {
            url: "/shkafy-otkhodyashchikh-liniy",
            title: "Шкафы отходящих линий",
          },
          {
            url: "/shkafy-operativnogo-toka",
            title: "Шкафы оперативного тока (гарантированного питания) ШОТ",
          },
        ],
      },
      {
        title: "Щиты переменного тока",
        nestedLinks: [
          {
            url: "/shkafy-pitaniya-i-upravleniya-electrodvigatelyami",
            title: "Шкафы для питания и управления электродвигателями",
          },
          {
            url: "/shchity-sobstvennykh-nuzhd",
            title: "Щиты собственных нужд (ЩСН–0,4 кВ)",
          },
          {
            url: "/shkafy-raspredelitelnye-nizkogo-napryazheniya",
            title: "Шкафы распределительные низкого напряжения ШРНН",
          },
          {
            url: "/glavnye-raspredelitelnye-shchity",
            title: "Главные распределительные щиты ГРЩ, ГРЩД",
          },
          {
            url: "/raspredelitelnye-shchity-SHCHO-70",
            title: "Распределительные щиты ЩО-70",
          },
          {
            url: "/RT30-88M",
            title: "РТЗО-88М",
          },
          {
            url: "/RT30-88",
            title: "РТЗО-88",
          },
        ],
      },
      {
        title: "Шкафы вторичной коммутации наружной установки",
        nestedLinks: [
          {
            url: "/shkafy-kleemnykh-zazhimov",
            title: "Шкафы клеммных зажимов",
          },
          {
            url: "/shkafy-obogreva-vyklyuchateley",
            title: "Шкафы обогрева выключателей (ШОВ)",
          },
          {
            url: "/shkafy_zazhimov_zashchity_shin",
            title: "Шкафы зажимов защиты шин (ШЗШ)",
          },
          {
            url: "/shkafy-zazhimov-transformatorov",
            title: "Шкафы зажимов трансформаторов напряжения (ШЗН)",
          },
          {
            url: "/shkafy-promezhutochnykh-zazhimov",
            title: "Шкафы промежуточных зажимов (ШЗВ)",
          },
        ],
      },
      {
        title: "Шкафы общего назначения",
        nestedLinks: [
          {
            url: "/yashchiki-silovye-YAR",
            title: "Ящики силовые серии ЯР (П)",
          },
          {
            url: "/yashchiki-upravleniya-YA5000-RUSM5000",
            title: "Ящики управления Я5000, РУСМ5000",
          },
          {
            url: "/yashchiki-upravleniya-osvesheniem",
            title: "Ящики управления освещением ЯУО-9601, ЯУО-9602",
          },
          {
            url: "/shkafy-vvoda-ABP",
            title: "Шкафы ввода с АВР серии ШУ (ЯУ) 8250, 8350",
          },
        ],
      },
      {
        title: "НКУ для распределения и учета электрической энергии",
        nestedLinks: [
          {
            url: "/VRU-1-11-10",
            title: "Вводно-распределительные устройства ВРУ-1",
          },
          {
            url: "/VRU-1-13-20",
            title: "Вводно-распределительные устройства ВРУ-3",
          },
          {
            url: "/VRU-1-17-70",
            title: "Вводно-распределительные устройства ВРУ-8",
          },
          {
            url: "/shkafy-raspredelitelnye-silovye-SHRS",
            title: "Шкафы распределительные силовые ШРС, ШР",
          },
          {
            url: "/punkty-raspredelitelnye-PR",
            title: "Пункты распределительные ПР",
          },
          {
            url: "/shchity-ucheta-raspredelitelniya-electroenergii",
            title: "Щиты учета и распределения электрической энергии ЩУР",
          },
          {
            url: "/shchitki-etazhnye-SHCHE",
            title: "Щитки этажные ЩЭ",
          },
        ],
      },
      {
        title: "НКУ для управления и автоматизации",
        nestedLinks: [
          {
            url: "/shkafy-avariynogo-osveshcheniya",
            title: "Шкафы аварийного освещения ШАО",
          },
          {
            url: "/shchity-stantsii-upravleniya",
            title: "Щиты станции управления ЩСУ",
          },
          {
            url: "/pulty-upravleniya-PRP",
            title: "Пульты управления ПРП",
          },
          {
            url: "/pulty-upravleniya-electroprivoda-zadvizhek",
            title: "Пульты управления электропривода задвижек БЭЗ",
          },
          {
            url: "/shchitki-osvetitelnye",
            title: "Щитки осветительные ОЩ, ОЩВ",
          },
          {
            url: "/ustroystva-avtomaticheskogo-vklyucheniya-rezerva",
            title: "Устройства автоматического включения резерва АВР",
          },
        ],
      },
      {
        title: "Шкафы собственных нужд",
        nestedLinks: [
          {
            url: "/shkafy-upravleniya-razeditionallyami",
            title: "Шкафы управления разъединителями (ШУР)",
          },
          {
            url: "/shkafy-pitaniya-solenoidov",
            title: "Шкафы питания соленоидов (ШПВ)",
          },
        ],
      },
    ],
  },
  {
    category: "Изготовление пластиковых корпусов и деталей",
    links: [
      {
        url: "/izgotovlenie-plastikovykh-korpusov-i-detaley",
        title: "Изготовление пластиковых корпусов и деталей",
      },
      {
        url: "/dizayn-i-razrabotka-konstruktsii",
        title: "Дизайн и разработка конструкции",
      },
      {
        url: "/prototipirovanie-i-lite-v-siliconovye-formy",
        title: "Прототипирование и литье в силиконовые формы",
      },
      {
        url: "/vakuumnoe-formovanie-listovykh-materialov",
        title: "Вакуумное формование листовых материалов",
      },
      {
        url: "/lite-plastmass-pod-davleniem",
        title: "Литье пластмасс под давлением",
      },
      {
        url: "/sborka-i-pokraska-gotovykh-izdeliy",
        title: "Сборка и покраска готовых изделий",
      },
    ],
  },
  {
    category: "Акустический контроль",
    links: [
      {
        url: "/sistema-akusticheskogo-kontrolya-echo",
        title: "Система акустического контроля Echo",
      },
      {
        url: "/izmeritelnyy-komplekt-v1",
        title: "Измерительный комплект V1.0",
      },
      {
        url: "/izmeritelnyy-komplekt-v2",
        title: "Измерительный комплект V2.0",
      },
    ],
  },
  {
    category: "Блоки питания",
    links: [
      {
        url: "/istochnik-pitaniya-AC-DC",
        title: "Источник питания AC/DC 125Вт",
      },
      {
        url: "/stanciya-bystroy-zaryadki",
        title: "Станция быстрой зарядки",
      },
    ],
  },
  {
    category: "Экологический мониторинг",
    links: [
      {
        url: "/environmental-stations",
        title: "Экологические станции",
      },
      {
        url: "/environmental-monitoring-station",
        title: "Станция экологического мониторинга",
      },
    ],
  }
]
