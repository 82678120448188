const breadcrumbItems = [
  {
    path: "/izgotovlenie-plastikovykh-korpusov-i-detaley",
    label: "Изготовление пластиковых корпусов и деталей",
  },
  { label: "Дизайн и разработка конструкции" },
]

const stages: string[] = [
  "Определение совместно с Заказчиком функциональных требований к разрабатываемому изделию",
  "Разработка конструкции на основе комплексного проектирования, учитывающего требования стилистических решений, функциональности и технологичности",
  "Оптимизация конструкции по вопросам эксплуатационных и технологических качеств",
  "Трехмерное компьютерное моделирование с использованием CAD/CAM систем",
  "Компоновочные работы",
  "Анализ собираемости конструкции",
  "Моделирование цвето-фактурного решения изделия с изготовлением цветных слайдов для визуализации детали на стадии проектирования",
  "Разработка единой математической модели",
  "Выполнение конструкторской документации в строгом соответствии с математической моделью",
]

export { breadcrumbItems, stages }
