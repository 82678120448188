export const characteristics = [
  { param: "Габариты корпуса, мм", value: "95×68×32" },
  {
    param: "Масса модуля, г",
    value: "260",
  },
  {
    param: "Масса модуля с датчиками и USB накопителем, г",
    value: "354",
  },
  {
    param: "Рабочая температура, ˚С",
    value: "от 0 до +85",
  },
]