import React, { FC } from "react"
// import { Roboto } from "next/font/google";

import { AncillaryFooter, Footer, Header, OrderButtons, Sidebar } from "./fragments"

// const roboto = Roboto({
//   subsets: ["cyrillic"],
//   weight: ["300", "400", "500", "700"],
//   style: ["normal"],
//   display: "swap",
//   variable: "--font-roboto",
// })

interface ILayout {
  children: JSX.Element
}

export const Layout: FC<ILayout> = ({ children }) => {
  return (
    <div>
    {/*<div className={roboto.className}>*/}
      <Header />
      <div className="relative mx-auto flex max-w-[1288px] justify-end gap-5 px-[24px] xl:flex-col xl:gap-0 xl:p-0">
        <Sidebar />
        <div className="w-full xl:px-6 md:px-0">
          {children}
          <AncillaryFooter />
        </div>
        <OrderButtons />
      </div>
      <Footer />
    </div>
  )
}
